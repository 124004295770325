@charset "utf-8";
@import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/components/card.sass";
@import "node_modules/bulma/sass/components/list.sass";
@import "node_modules/bulma/sass/components/menu.sass";
@import "node_modules/bulma/sass/components/navbar.sass";
@import "node_modules/bulma/sass/components/media.sass";
@import "node_modules/bulma/sass/elements/image.sass";
@import "node_modules/bulma/sass/elements/container.sass";
@import "node_modules/bulma/sass/elements/title.sass";
@import "node_modules/bulma/sass/layout/_all.sass";
@import "node_modules/bulma/sass/base/_all.sass";


@font-face {
  font-family: 'Roboto', sans-serif;
  font-display: auto;
}

html, body, .body {
   height: 100%;
 }
* {
  font-family: 'Roboto', sans-serif;
}
header,
main,
.nav {
  padding: 20px;
}

header {
  box-shadow: 0 2px 6px 0 hsla(0,0%,0%,0.2);
}
.logo {
  height: 65px;
  width: 150px;
  padding-left: 20px;
}

.align-end {
  text-align: end;
}
.nav-item,
a.nav-item {
  color: hsl(24,12,30%);
  text-transform: uppercase;
  padding: 10px 30px;
}

.navbar {
  margin-top: 20px;
}

.nav-item.vertical {
  height: 40px;
  margin-top: 20px;
  display: block;
  width: auto;
  padding: 0;
}

.container {
  padding-top: 50px;
}

@media screen and (min-width: 1334px) {
  .container {
      max-width: 1334px;
  }
}

.nav-item {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  display: inline-block;
}
.nav-item:focus,
.nav-item:active,
.nav-item:hover {
  outline-width: 0;
}
.nav-item::after {
  margin-top: 7px;
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: #A6E22E;
  background: -webkit-linear-gradient(left, #A6E22E, orange);
  background: -o-linear-gradient(right, #A6E22E, orange);
  background: -moz-linear-gradient(right, #A6E22E, orange);
  background: linear-gradient(to right, #A6E22E, orange);
  transition: width 0.3s;
}
.nav-item.active::after {
  width: 100%;
  background: rgba(0,0,0,0.7);
}
.nav-item:hover::after {
  width: 100%;
  transition: width 0.2s;
}

@media (max-width: 80.0rem) {
  .hidden-tablet {
    display: none !important;
  }
  .nav-item,
  a.nav-item {
    padding: 10px;
  }
}
@media (min-width: 80.0rem) {
  .hidden-desktop {
    display: none !important;
  }
}
.navbar.is-fixed-top {
  -webkit-box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.2);
}

h1 {
  margin-bottom: 50px;
  margin-top: 50px;
}
.page {
  min-height: 100%;
  padding: 80px 0;
  width: 100%;
}
.navbar {
  padding-top: 20px;
  margin-top: 0;
}
.subtitle {
  font-size: 3rem;
}

@media screen and (max-width: 1088px) {
  #navbar {
    width: 15em;
    height: 100vh;
    background-color: #ffffff;
    transition: .4s;
    position: fixed;
    top: 86px;
    display: block;
    box-shadow: 0 2px 6px 0 hsla(0,0%,0%,0.2);
    -webkit-box-shadow: 0 2px 6px 0 hsla(0,0%,0%,0.2);
    -moz-box-shadow: 0 2px 6px 0 hsla(0,0%,0%,0.2);
  }
  #navbar.is-closed {
    -webkit-transform: translateX(-16em);
            transform: translateX(-16em);
  }
  #navbar .nav-item {
    display: block;
    height: 70px;
  }
  .page {
    padding: 80px 20px;
  }
}

.footer {
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.2);
  background: hsl(24,21,93%);
  padding: 0;
  padding-bottom: 50px;
}
.footer a {
  color: hsl(24,12,30%);
  text-decoration: underline;
}
.footer li {
  padding: 10px 0;
}
.placeholderimage {
  width: 400px;
  height: 400px;
}
.padded {
  padding-top: 10px;
}
.card {
  height: 100%;
}
.title {
  font-weight: 400;
}

.columns {
    display: flex;
    flex-wrap: wrap;
 }

.column {
    flex: 1 0 500px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 1rem .25em;
    padding: .75rem
}
@media screen and (min-width: 40em) {
    .column {
       max-width: calc(50% - 0.5em);
    }
}

@media screen and (min-width: 60em) {
    .column {
        max-width: calc(25% - 0.5em);
    }
}
